<script>
import {defineComponent} from 'vue'
import store from '@/state/store'
import axios from "axios";
import Swal from "sweetalert2";
import SelectCompany from "@/components/custom/SelectCompany.vue";

export default defineComponent({
  name: "OverviewTab",
  props: {
    user: {
      type: Object,
      required: true
    },
    show_info: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      userRoles: [],
      is_saving: false,
      user_types: [
        {label: 'Client', value: 'client'},
        {label: 'Admin', value: 'admin'},
        {label: 'Manager', value: 'manager'},
        {label: 'Counterparty', value: 'counterparty'},
        {label: 'Moscow Manager', value: 'moscow_manager'},
        {label: 'Central Asia Manager', value: 'moscow_manager'},
      ]
    }
  },
  components: {
    SelectCompany
  },
  computed: {
    userComputed() {
      return this.user || {}
    },
    currentUserIsAdmin() {
      try {
        return store.state.user.role.toLowerCase() === 'admin'
      } catch {
        return false
      }
    },
  },
  methods: {
    async saveUser() {
      this.is_saving = true
      try {
        await axios.put(`/user/${this.userComputed.id}/update/`, {
          first_name: this.userComputed.first_name,
          last_name: this.userComputed.last_name,
          email: this.userComputed.email,
          company_id: this.userComputed.company ? this.userComputed.company.id : this.userComputed.company,
          username: this.userComputed.username,
          telegram_id: this.userComputed.telegram_id ? this.userComputed.telegram_id : ' ',
          telegram_access: this.userComputed.telegram_access,
          is_admin: this.userComputed.role.toLowerCase() === 'admin',
          is_manager: this.userComputed.role.toLowerCase() === 'manager',
          is_client: this.userComputed.role.toLowerCase() === 'client',
          is_counterparty: this.userComputed.role.toLowerCase() === 'counterparty',
          type: this.userComputed.type,
        })
        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'User updated!',
          showConfirmButton: false,
          timer: 3000
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'Failed to update user. Please, make sure that you filled all the fields!',
          showConfirmButton: false,
          timer: 3000
        })
      }
      this.is_saving = false
    },

    onCompanySelect(company) {
      this.userComputed.company = {
        id: company.value,
        name: company.label
      }
    }
  },
})
</script>

<template>
  <div class="row">
    <div v-if="show_info" class="col-xxl-3">
      <div class="card my-0">
        <div class="card-body">
          <h5 class="card-title mb-3">Info</h5>
          <p class="my-0">Hello, i am {{ userComputed.first_name + ' ' + userComputed.last_name }}</p>
        </div>
      </div>
    </div>
    <div :class="{'col-xxl-9': show_info, 'col-12': !show_info}">
      <div class="card my-0">
        <div class="card-body">
          <h5 class="card-title mb-3">About</h5>
          <form @submit.prevent="saveUser()">
            <div class="row gy-4 align-items-center">
              <div class="col-lg-6">
                <label class="form-label text-dark">First Name</label>
                <input v-model="userComputed.first_name" class="form-control" type="text" placeholder="First name">
              </div>
              <div class="col-lg-6">
                <label class="form-label text-dark">Last Name</label>
                <input v-model="userComputed.last_name" class="form-control" type="text" placeholder="Last name">
              </div>
              <div class="col-lg-6">
                <label class="form-label text-dark">E-mail</label>
                <input v-model="userComputed.email" class="form-control" type="email" placeholder="User email">
              </div>
              <div class="col-lg-6">
                <template v-if="userComputed.company === null">
                  <label class="form-label text-dark">Company</label>
                  <SelectCompany @onSelect="onCompanySelect"/>
                </template>
                <template v-if="userComputed.company">
                  <label class="form-label text-dark">Company</label>
                  <SelectCompany @onSelect="onCompanySelect"
                                 :current_company="{id: userComputed.company.id, name: userComputed.company.name }"/>
                </template>
              </div>
              <template v-if="currentUserIsAdmin">
                <div class="col-lg-6">
                  <label class="form-label text-dark">User Roles</label>
                  <select v-model="userComputed.type" class="form-select">
                    <option v-for="type in user_types" :value="type.value" :key="`user_type_${type.value}`">
                      {{ type.label }}
                    </option>
                  </select>
                </div>

                <div class="col-lg-6">
                  <label class="form-label text-dark ">
                    Telegram Access
                  </label>

                  <div class="input-group">
                    <button class="btn btn-light me-1">
                      <input v-model="userComputed.telegram_access" class="form-check-input" type="checkbox"
                             id="userHasTelegramAccess">
                    </button>
                    <input v-model="userComputed.telegram_id" type="number" class="form-control"
                           placeholder="Telegram id">
                  </div>

                </div>
              </template>
              <div class="col-12 text-end">
                <b-button
                    :type="is_saving ? 'button' : 'submit'" variant="success"
                    :disabled="is_saving">
                  {{ is_saving ? 'Saving...' : 'Save' }}
                </b-button>
              </div>
            </div>
          </form>

        </div>
        <!--end card-body-->
      </div>

    </div>
    <!--end col-->
  </div>
</template>

<style scoped>

</style>