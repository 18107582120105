<script>
import {SimpleBar} from "simplebar-vue3";
import axios from "axios";

export default {
  name: "VisitCustomerProfile",
  components: {SimpleBar},
  data() {
    return {
      customers_list: [],
      search_text: ''
    }
  },
  methods: {
    async getCustomers() {
      try {
        let response = await axios.get('/customer/customer_list_with_cabinet/')
        this.customers_list = response.data.results || []
      } catch {
        this.customers_list = []
      }
    },
    onDropdownOpened() {
      this.$nextTick(() => {
        this.$refs.customerSearch.focus()
      })
    },
    openCustomerProfileModal(customer) {
      this.$router.push({name: 'customer_profile_pre_view', params: {customer_id: customer.id}})
    }
  },
  computed: {
    customers() {
      if (!this.search_text) {
        return this.customers_list; // Return all codes if search_text is empty
      }
      const searchTextLower = this.search_text.toLowerCase().trim()
      return this.customers_list.filter(customerObj =>
          customerObj.name.toLowerCase().includes(searchTextLower)
      )
    }
  },
  mounted() {
    this.getCustomers()
  }
}
</script>

<template>
  <div class="dropdown topbar-head-dropdown mx-1 header-item">

    <button
        type="button"
        class="btn border btn-icon btn-ghost-success"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        @click="onDropdownOpened()"
    >
      <i class="ri-team-fill fs-3 align-middle text-success"></i>
    </button>

    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
         aria-labelledby="page-header-notifications-dropdown">
      <div class="dropdown-head bg-soft-success bg-pattern rounded-top">
        <div class="d-flex justify-content-between flex-wrap p-3">
          <h6 class="fs-16 fw-semibold text-dark mb-0">
            Customers Profile
          </h6>
          <h5 class="mb-0">
            <span class="badge bg-success">
              {{ customers.length }} Customers
            </span>
          </h5>
        </div>
        <div class="px-3 pb-2">
          <p class="text-dark mb-0">
            Click on a customer to see his profile
          </p>
        </div>
      </div>

      <div class="tab-content" id="customerItemsTabContent">
        <div class="tab-pane fade py-2 ps-2 show active" role="tabpanel">
          <div class="w-100 pe-2 ps-1 my-2">
            <input v-model="search_text" ref="customerSearch" class="form-control w-100"
                   placeholder="Search for customers...">
          </div>
          <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">

            <router-link
                v-for="(customer, index) in customers" :key="`customer_${customer.id}`"
                :to="{name: 'customer_profile_pre_view', params: {customer_slug: customer.slug}}"
                class="text-reset notification-item d-block ps-2 dropdown-item position-relative cursor-pointer">
              <div class="d-flex gap-2 justify-content-start align-items-center">
                <span class="badge badge-soft-success">{{ index + 1 }}.</span>
                <div class="flex-1">
                  <h6 class="mt-0 mb-0 lh-base">
                    {{ customer.name }}
                  </h6>
                </div>
              </div>
            </router-link>

          </SimpleBar>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>