<script>
import i18n from "../i18n";
import ContainerSearch from "./ContainerSearch.vue";
import store from "../state/store";
import TaskLoading from "@/components/custom/TaskLoading.vue";
import VisitCustomerProfile from "@/components/custom/VisitCustomerProfile.vue";
import SystemLimitations from "@/components/custom/SystemLimitations.vue";
import Apps from "@/components/Apps.vue";


export default {
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.svg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/russia.svg"),
          language: "ru",
          title: "русский",
        },
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,

      available_years: [2022, 2023],
      current_theme: ''
    };
  },
  components: {
    ContainerSearch,
    TaskLoading,
    VisitCustomerProfile,
    SystemLimitations,
    Apps
  },

  methods: {
    logout() {
      store.dispatch("logout");
      window.location.href = "/login";
    },
    setYear(event) {
      store.dispatch('setYear', event.target.value)
    },
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
          document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu")
            ? document.body.classList.remove("menu")
            : document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
              ? document.documentElement.setAttribute("data-sidebar-size", "")
              : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg"
              ? document.documentElement.setAttribute("data-sidebar-size", "sm")
              : document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
            ? document.body.classList.remove("twocolumn-panel")
            : document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
          !document.fullscreenElement &&
          /* alternative standard method */
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
    },
    toggleDarkMode() {
      switch (document.documentElement.getAttribute("data-layout-mode")) {
        case "dark":
          document.documentElement.setAttribute("data-layout-mode", "light");
          document.documentElement.setAttribute("data-sidebar", "light");
          this.current_theme = 'light'
          break;
        case "light":
          document.documentElement.setAttribute("data-layout-mode", "dark");
          document.documentElement.setAttribute("data-sidebar", "dark");
          this.current_theme = 'dark'
          break;
      }
    },
  },
  computed: {
    getUserFullName() {
      return store.state.user.full_name.trim().length
          ? store.state.user.full_name
          : store.state.user.email;
    },
    canViewDevsSection() {
      let usersId = [1];
      return usersId.includes(store.state.user.id);
    },
    systemYear: {
      get() {
        return store.state.year
      },
      set(value) {
        store.dispatch('setYear', value)
        window.location.reload()
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", function () {
      var pageTopbar = document.getElementById("page-topbar");
      if (pageTopbar) {
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
            ? pageTopbar.classList.add("topbar-shadow")
            : pageTopbar.classList.remove("topbar-shadow");
      }
    });
    if (document.getElementById("topnav-hamburger-icon"))
      document
          .getElementById("topnav-hamburger-icon")
          .addEventListener("click", this.toggleHamburgerMenu);


    this.current_theme = document.documentElement.getAttribute("data-layout-mode")
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->

          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22"/>
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="17"/>
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img
                    src="@/assets/images/interrail-logo.png"
                    alt=""
                    height="22"
                />
                jen
              </span>
              <span class="logo-lg">
                <img
                    src="@/assets/images/interrail-logo.png"
                    alt=""
                    height="17"
                />
                hdc
              </span>
            </router-link>
          </div>

          <button
              type="button"
              class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
              id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <div class="ps-2">
            <!-- App Search-->
            <ContainerSearch/>
          </div>
        </div>
        <div class="d-flex align-items-center">

          <div class="d-none d-md-block">
            <TaskLoading/>
          </div>

          <Apps/>

          <SystemLimitations/>
          <VisitCustomerProfile/>

          <!--          <div class="dropdown ms-1 topbar-head-dropdown header-item">-->
          <!--            <button-->
          <!--                type="button"-->
          <!--                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"-->
          <!--                data-bs-toggle="dropdown"-->
          <!--                aria-haspopup="true"-->
          <!--                aria-expanded="false"-->
          <!--            >-->
          <!--              <img-->
          <!--                  id="header-lang-img"-->
          <!--                  src="@/assets/images/flags/us.svg"-->
          <!--                  alt="Header Language"-->
          <!--                  height="20"-->
          <!--                  class="rounded"-->
          <!--              />-->
          <!--            </button>-->
          <!--            <div class="dropdown-menu dropdown-menu-end">-->
          <!--              &lt;!&ndash; item&ndash;&gt;-->
          <!--              <a-->
          <!--                  href="javascript:void(0);"-->
          <!--                  v-for="(entry, i) in languages"-->
          <!--                  :key="`Lang${i}`"-->
          <!--                  :value="entry"-->
          <!--                  @click="setLanguage(entry.language, entry.title, entry.flag)"-->
          <!--                  :class="{ active: lan === entry.language }"-->
          <!--                  class="dropdown-item notify-item language py-2"-->
          <!--                  data-lang="en"-->
          <!--                  title="English"-->
          <!--              >-->
          <!--                <img-->
          <!--                    :src="entry.flag"-->
          <!--                    alt="user-image"-->
          <!--                    class="me-2 rounded"-->
          <!--                    height="18"-->
          <!--                />-->
          <!--                <span class="align-middle">{{ entry.title }}</span>-->
          <!--              </a>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="dropdown ms-sm-3 header-item">

            <button
                type="button"
                class="btn p-0"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              <span class="d-flex align-items-center p-2 border" style="border-radius: 8px">
                <i class="bx bx-user text-success fs-2"></i>
                <span class="text-start ms-xl-2">
                  <span
                      class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                  >
                    {{ getUserFullName }}
                  </span>
                  <span
                      class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"
                  >
                  </span>
                </span>
              </span>
            </button>

            <div class="dropdown-menu dropdown-menu-end">
              <h6 class="dropdown-header">Welcome {{ getUserFullName }}!</h6>
              <router-link
                  v-if="canViewDevsSection"
                  class="dropdown-item"
                  style="cursor: pointer"
                  :to="{name: 'devs'}"
              >
                <i
                    class="ri-terminal-box-line text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle" data-key="t-logout">Devs</span>
              </router-link>
              <a class="dropdown-item" data-toggle="fullscreen" style="cursor: pointer" @click="initFullScreen">
                <i
                    class="bx bx-fullscreen text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle" data-key="t-logout">Full Screen</span>
              </a>
              <a class="dropdown-item light-dark-mode" style="cursor: pointer" @click="toggleDarkMode">
                <i
                    class="bx bx-moon text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle" data-key="t-logout">Theme</span>
              </a>
              <a class="dropdown-item" style="cursor: pointer" @click="logout">
                <i
                    class="mdi mdi-logout text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle" data-key="t-logout">Logout</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style>

</style>