let eventGuid = 0
// let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = []

export function createEventId() {
    return String(eventGuid++)
}

export const categories = [{
    name: 'Red', value: 'bg-danger'
}, {
    name: 'Green', value: 'bg-success'
}, {
    name: 'Blue', value: 'bg-primary'
}, {
    name: 'Yellow', value: 'bg-warning'
}, {
    name: 'Pink', value: 'bg-secondary'
}, {
    name: 'Steel Blue', value: 'bg-info'
}, {
    name: 'Dark', value: 'bg-dark'
},];

export const types = [{
    name: 'Import', value: 'import'
}, {
    name: 'Export', value: 'export'
}, {
    name: 'Transit', value: 'transit'
}]
export const statuses = [{
    name: 'Expecting', value: 'expecting'
}, {
    name: 'Dispatched', value: 'dispatched'
}, {
    name: 'Completed', value: 'completed'
},]