<script>
import {tasksMethods, tasksComputed} from "@/state/helpers";
import {SimpleBar} from "simplebar-vue3";
import moment from "moment";

export default {
  name: "TaskLoading",
  components: {SimpleBar},
  methods: {
    ...tasksMethods,
    clearTasks() {
      this.clearAll()
    },
    formatDate(date) {
      if (!date) return 'Unknown'
      return moment(date).fromNow()
    }
  },
  computed: {
    ...tasksComputed,
    tasks_list: {
      get() {
        return this.tasks
      },
      set(value) {
        this.addTask(value)
      }
    },
    pending_tasks() {
      return this.tasks_list.filter(task => task._status === 'pending')
    },
    started_tasks() {
      return this.tasks_list.filter(task => task._status === 'started')
    },
    success_tasks() {
      return this.tasks_list.filter(task => task._status === 'success')
    },
    failed_tasks() {
      return this.tasks_list.filter(task => task._status === 'failed')
    },
    task_color() {
      if (this.tasks_list.length) {
        return this.tasks_list.every(task => task._status !== 'success') ? 'bg-soft-warning border-warning' : 'bg-soft-success border-success'
      } else {
        return 'bg-soft-success border-success'
      }
    },
    first_task() {
      return this.tasks_list[0] ? this.tasks_list[0] : {}
    }
  },

  mounted() {
    this.initTasks()
  }
}
</script>

<template>

  <div class="d-flex align-items-center gap-2 flex-wrap">
    <div v-if="Object.keys(first_task).length"
         class="d-none d-xxl-flex justify-content-center align-items-center header-item ">
      <b-alert show dismissible variant="light" class="alert-top-border fade show mb-0 border-0" role="alert">
        <i class="mdi me-3 align-middle fs-16" :class="{
                        'mdi-loading mdi-spin text-secondary': first_task._status === 'started',
                        'mdi-loading mdi-spin text-warning': first_task._status === 'pending',
                        'mdi-circle text-success': first_task._status === 'success',
                        'mdi-circle text-danger': first_task._status === 'failure',
                      }"></i>
        {{ first_task.title }}
      </b-alert>
    </div>

    <div class="dropdown topbar-head-dropdown mx-1 header-item">

      <button
          type="button"
          class="btn border btn-icon btn-ghost-primary"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
      >
        <i class="bx bx-task fs-3 align-middle"></i>
      </button>

      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
           aria-labelledby="page-header-notifications-dropdown">
        <div class="dropdown-head bg-primary bg-pattern rounded-top">
          <div class="p-3">
            <b-row class="align-items-center">
              <b-col>
                <h6 class="fs-16 fw-semibold text-white">
                  Background Tasks
                </h6>

                <div>
                  <div class="d-flex align-items-center gap-3">
                  <span v-b-tooltip.hover title="Tasks pending" class="text-light">
                    <i class="mdi mdi-circle text-warning"></i> {{ pending_tasks.length }}
                  </span>
                    <span v-b-tooltip.hover title="Tasks started" class="text-light">
                    <i class="mdi mdi-circle text-info"></i> {{ started_tasks.length }}
                  </span>
                    <span v-b-tooltip.hover title="Tasks done" class="text-light">
                    <i class="mdi mdi-circle text-success"></i> {{ success_tasks.length }}
                  </span>
                    <span v-b-tooltip.hover title="Tasks failed" class="text-light">
                    <i class="mdi mdi-circle text-danger"></i> {{ failed_tasks.length }}
                  </span>
                  </div>
                </div>
              </b-col>
              <b-col cols="auto" class="dropdown-tabs">
                <b-badge variant="soft-light" class="badge-soft-light fs-13"> {{ tasks_list.length }} Tasks</b-badge>
              </b-col>
            </b-row>
          </div>
        </div>

        <div class="tab-content" id="notificationItemsTabContent">
          <div class="tab-pane fade py-2 ps-2 show active" id="all-noti-tab" role="tabpanel">
            <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">

              <div v-for="task in tasks_list" :key="`task_${task.id}`"
                   class="text-reset notification-item d-block dropdown-item position-relative">
                <div class="d-flex">
                  <div class="flex-1">
                    <b-link href="#!" class="stretched-link">
                      <h6 class="mt-0 mb-2 lh-base">
                        {{ task.title }}
                      </h6>
                    </b-link>
                    <p class="mb-0 fs-11 fw-medium text-muted">
                    <span>
                      <i class="mdi" :class="{
                        'mdi-loading mdi-spin text-secondary': task._status === 'started',
                        'mdi-loading mdi-spin text-warning': task._status === 'pending',
                        'mdi-circle text-success': task._status === 'success',
                        'mdi-circle text-danger': task._status === 'failure',
                      }"></i>
                    {{ formatDate(task.created_at) }}
                    </span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="my-3 text-center" v-if="tasks_list.length === 0">
                There are no tasks
              </div>

              <div class="my-3 text-center" v-if="tasks_list.length > 0">
                <b-button variant="soft-danger" @click="clearTasks()">
                  Clear All
                  <i class="bx bx-x align-middle fs-4"></i>
                </b-button>
              </div>

            </SimpleBar>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

@keyframes scale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0 5px;
  animation: scale 1s infinite ease-in-out;
}

.circle:nth-child(1) {
  animation-delay: 0s;
}

.circle:nth-child(2) {
  animation-delay: 0.5s;
}

.circle:nth-child(3) {
  animation-delay: 1s;
}
</style>