<script>
import TrainCreate from "@/views/pages/trains/modals/TrainCreate.vue";
import OrderCreate from "@/components/modals/CreateOrder.vue";
import InquiryCreate from "@/views/pages/inquires/modals/CreateInquiry.vue";
import AVRCreate from "@/components/modals/CreateAVR.vue";
import CreateProduct from '@/components/modals/CreateProduct.vue'
import CreateStation from '@/components/modals/CreateStation.vue'

import Swal from "sweetalert2";

export default {
  components: {
    TrainCreate,
    OrderCreate,
    InquiryCreate,
    AVRCreate,
    CreateProduct,
    CreateStation
  },
  data() {
    return {
      services: [
        {
          name: 'Accounts',
          img: require("@/assets/images/users/users.webp"),
        },
        {
          name: 'Application',
          img: require("@/assets/images/document.webp"),
          route_name: 'application_create',
        },
        {
          name: 'AVR',
          img: require("@/assets/images/excel.png"),
          onClick: () => {
            this.$refs.AVRCreate.openModal()
          }
        },
        {
          name: 'Inquiry',
          img: require("@/assets/images/inquiry.webp"),
          onClick: () => {
            this.$refs.inquiryCreateModal.openModal()
          }
        },
        {
          name: 'Order',
          img: require("@/assets/images/order-diamond-icon.webp"),
          onClick: () => {
            this.$refs.orderCreateModal.openModal()
          }
        },
        {
          name: 'Train',
          img: require("@/assets/images/train.webp"),
          onClick: () => {
            this.$refs.trainCreateModal.openModal()
          }
        },
        {
          name: 'Product',
          img: require("@/assets/images/box.png"),
          onClick: () => {
            this.$refs.createProduct.openModal()
          }
        },
        {
          name: 'Station',
          img: require("@/assets/images/train-location-pin.png"),
          onClick: () => {
            this.$refs.createStation.openModal()
          }
        },
      ]
    }
  },
  methods: {
    handleCLick(service) {
      if (service.route_name) {
        this.$router.push({name: service.route_name})
      } else {
        console.log('No route name provided')
      }
    },
    async onTrainCreated(axios_response_data) {
      console.log(axios_response_data)
      await Swal.fire({
        showConfirmButton: false,
        timer: 10000,
        html: `
          <div class="text-center">
            <img src="${require('@/assets/images/train.webp')}" class="img-fluid my-4" style="height: 80px; width: 80px"/>
            <h3 class="mb-4">Train created successfully</h3>
            <a href="/trains/${axios_response_data.slug}/">
            <button class="btn btn-lg btn-success">View train</button>
</a>
          </div>
        `
      })
    },
    async onInquiryCreated() {
      await Swal.fire({
        icon: 'success',
        showConfirmButton: false,
        timer: 10000,
        title: 'Inquiry created successfully',
      })
    },

  },
}
</script>

<template>
  <TrainCreate ref="trainCreateModal" @trainCreated="onTrainCreated($event)"/>
  <OrderCreate ref="orderCreateModal"/>
  <InquiryCreate ref="inquiryCreateModal" @onInquiryCreated="onInquiryCreated($event)"/>
  <AVRCreate ref="AVRCreate"/>
  <CreateProduct ref="createProduct"/>
  <CreateStation ref="createStation"/>

  <div class="dropdown topbar-head-dropdown mx-1 header-item">
    <button type="button" class="btn border btn-icon btn-ghost-secondary"
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="bx bx-category-alt fs-22 "></i>
    </button>
    <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
      <div class="
                  p-3
                  border-top-0 border-start-0 border-end-0 border-dashed border
                ">
        <b-row class="align-items-center">
          <b-col>
            <h6 class="m-0 fw-semibold fs-15">Services</h6>
          </b-col>
        </b-row>
      </div>

      <div class="p-2">

        <b-row class="g-0">
          <template v-for="(service, index) in services" :key="`service_${service.name}_${index + 1}`">
            <div class="col-6"
                   @mouseover="service.hovered = true"
                   @mouseleave="service.hovered = false"
            >
              <div class="dropdown-icon-item position-relative" :class="service.onClick ? '' : ''"
                   @click="service.onClick ? service.onClick() : handleCLick(service)">
                <img :src="service.img" class="img-fluid" style="height: 40px; width: 40px"/>
                <span>
                  {{ service.name }}
                </span>
              </div>
            </div>
          </template>
        </b-row>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>