<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "VisitCustomerProfile",
  data() {
    return {
      search_text: '',
      restrictions: [
        {
          name: 'Applications',
          limit: 10,
        },
        {
          name: 'Codes',
          limit: 5,
        },
        {
          name: 'Invoices',
          limit: 3,
        },
        {
          name: 'Orders',
          limit: 7,
        },
      ],
      showModal: false,
      selected_restriction: {},
      save_request: {
        status: 'initial',
        message: '',
        details: ''
      }
    }
  },
  methods: {
    async getRestrictions() {
      try {
        let response = await axios.get('/core/restrictions/')
        this.restrictions = response.data.results || []
      } catch {
        this.restrictions = []
      }
    },
    async saveRestriction() {
      this.save_request.status = 'loading'
      try {
        await axios.post(`/core/restrictions/${this.selected_restriction.id}/`, {
          value: this.selected_restriction.value
        })
        await Swal.fire({
          title: 'Success!',
          text: 'Restriction updated successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
        this.save_request.status = 'success'
      } catch {
        await Swal.fire({
          title: 'Error!',
          text: 'Error updating restriction',
          icon: 'error',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
        this.save_request.status = 'error'
      }
    },
    openModal(restriction) {
      this.selected_restriction = restriction || {}
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
  },
  mounted() {
    this.getRestrictions()
  }
}
</script>

<template>
  <div class="dropdown topbar-head-dropdown mx-1 header-item">

    <button
        type="button"
        class="btn border btn-icon btn-ghost-secondary"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
    >
      <i class="bx bx-cog fs-3 align-middle"></i>
    </button>

    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
         aria-labelledby="page-header-notifications-dropdown">
      <div class="dropdown-head bg-soft-success bg-pattern rounded-top">
        <div class="d-flex justify-content-between flex-wrap p-3">
          <h6 class="fs-16 fw-semibold text-dark mb-0">
            Control System Restrictions
          </h6>
        </div>
        <div class="px-3 pb-2">
          <p class="text-dark mb-0">
            List of restrictions currently applied.<br>
            Click on the restriction to see the details.
          </p>
        </div>
      </div>

      <div class="tab-content" id="customerItemsTabContent">
        <div class="tab-pane fade py-2 ps-2 show active" role="tabpanel">
          <b-row gutter-y="2">
            <b-col sm="6" v-for="r in restrictions" :key="`r_${r.name}`">
              <div
                  @click="openModal(r)"
                  class="text-reset notification-item d-block ps-2 dropdown-item position-relative cursor-pointer">
                <div class="d-flex flex-column gap-2 justify-content-start align-items-center">
                  <div class="avatar-xs">
                    <div class="avatar-title fs-16 rounded-circle bg-soft-danger text-danger">
                      {{ r.value || 0 }}

                    </div>
                  </div>
                  <div class="flex-1">
                    <h6 class="mt-0 mb-0 lh-base text-center">
                      {{ r.name || '-' }}
                    </h6>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>

  <b-modal v-model="showModal"
           id="RestrictionsModal"
           :title="selected_restriction.name" size="lg" hide-footer centered>
    <form @submit.prevent="saveRestriction()">
      <p v-if="selected_restriction.summary">
        {{ selected_restriction.summary }}
      </p>
      <b-row class="justify-content-between align-items-end">
        <b-col sm="8">
          <label class="form-label">Quantity</label>
          <input v-model="selected_restriction.value" class="form-control" type="number" placeholder="Quantity">
        </b-col>
        <b-col sm="4">
          <b-button
              :type="save_request.status === 'loading' ? 'button' : 'submit'"
              :disabled="save_request.status === 'loading'"
              variant="success" class="w-100">
            {{ save_request.status === 'loading' ? 'Saving..' : 'Save' }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-modal>
</template>

<style scoped>

</style>