let Staff = 'staff'
let Client = 'client'
let Test = 'test'


let Menu = [
    {
        sectionTitle: "Accounts",
        pages: [
            {
                _name: 'accounts_users_list',
                heading: "Users",
                route: "/accounts/user/",
                icon: "bx bxs-group",
                permission: [],
            },
            {
                _name: 'accounts_customers_list',
                heading: "Customers",
                route: "/accounts/customer/",
                icon: "bx bxs-group",
                permission: [],
            },
            {
                _name: 'accounts_counterparties_list',
                heading: "Counterparties",
                route: "/accounts/counterparty/",
                icon: "bx bxs-group",
                permission: [],
            },
        ],
    },
    {
        sectionTitle: "Codes",
        pages: [
            {
                _name: 'HomePage',
                heading: "Dashboard",
                route: "/",
                icon: "bx bxs-star",
                permission: [],
            },
            {
                _name: 'applications_list',
                heading: "Applications",
                route: "/applications/",
                icon: "ri-file-paper-line",
                permission: [Staff],
            },
            {
                _name: 'codes_list',
                heading: "Codes",
                route: "/codes/",
                icon: "ri-file-paper-line",
                permission: [Staff],
            },
            {
                _name: 'trains',
                heading: "Trains",
                route: "/trains",
                icon: "ri-file-paper-line",
                permission: [Staff],
                children: [
                    {
                        _name: 'trains_list',
                        heading: "List",
                        route: "/",
                    },
                    {
                        _name: 'train_schedule',
                        heading: "Schedule",
                        route: "/schedule/",
                    }
                ]
            },
        ],
    },
    {
        sectionTitle: "Orders",
        pages: [
            {
                _name: 'pre_orders',
                heading: "Pre Orders",
                route: "/pre-orders/",
                icon: "bx bx-file-blank",
                permission: [Staff],
            },
            {
                _name: 'orders',
                heading: "Orders",
                route: "/orders",
                icon: "ri-file-paper-line",
                permission: [Client, Staff],
                children: [
                    {
                        _name: 'order_container_list',
                        heading: "Container",
                        route: "/container/",
                        children: [
                            {
                                _name: 'order_container_list',
                                heading: "List",
                            },
                            {
                                _name: 'create_container',
                                heading: "Create",
                            },
                            {
                                _name: 'orders_container_update',
                                heading: "Update",
                            },
                            {
                                _name: 'orders_container_detail',
                                heading: "Details",
                            }
                        ]
                    },
                    {
                        _name: 'order_wagon_list',
                        heading: "Wagon",
                        route: "/wagon/",
                        children: [
                            {
                                _name: 'order_wagon_list',
                                heading: "List",
                            },
                            {
                                _name: 'create_wagon',
                                heading: "Create",
                            },
                            {
                                _name: 'orders_wagon_update',
                                heading: "Update",
                            },
                            {
                                _name: 'orders_wagon_detail',
                                heading: "Details",
                            }
                        ]
                    },
                    {
                        _name: 'order_empty_wagon_list',
                        heading: "Empty",
                        route: "/empty-wagon/",
                        children: [
                            {
                                _name: 'order_empty_wagon_list',
                                heading: "List",
                            },
                            {
                                _name: 'create_empty_wagon',
                                heading: "Create",
                            },
                            {
                                _name: 'orders_empty_wagon_update',
                                heading: "Update",
                            },
                            {
                                _name: 'orders_empty_wagon_detail',
                                heading: "Details",
                            }
                        ]
                    },
                ]
            },
            {
                _name: 'invoices_list',
                heading: "Invoices",
                route: "/invoices/list",
                icon: "bx bx-file-blank",
                permission: [Staff],
            },
            {
                _name: 'rates_list',
                heading: "Rates",
                route: "/rates",
                icon: "bx bx-dollar-circle",
                permission: [Staff],
            },
        ],
    },
    {
        sectionTitle: "General",
        pages: [
            {
                _name: 'general',
                heading: "General",
                route: "/general",
                icon: "bx bxs-wrench",
                permission: [Test],
                children: [
                    {
                        _name: 'general_products_list',
                        heading: "Products",
                        route: "/products/",
                    },
                    {
                        _name: 'general_categories',
                        heading: "Categories",
                        route: "/categories/",
                    },
                    {
                        _name: 'general_counterparties',
                        heading: "Counterparties",
                        route: "/counterparties/",
                    },
                    {
                        _name: 'general_stations',
                        heading: "Stations",
                        route: "/stations/",
                    },
                    {
                        _name: 'general_terminals',
                        heading: "Terminals",
                        route: "/terminals/",
                    },
                    {
                        _name: 'general_shipment_products',
                        heading: "Shipment Products",
                        route: "/shipment-products/",
                    },
                    {
                        _name: 'general_roles',
                        heading: "Roles",
                        route: "/roles/",
                    },
                    {
                        _name: 'general_companies',
                        heading: "Companies",
                        route: "/companies/",
                    },
                ]
            },
        ],
    },
    {
        sectionTitle: "Terminal",
        pages: [
            {
                _name: 'terminal_index',
                heading: "Terminal",
                route: "/terminal",
                icon: "bx bxs-star",
                permission: [Staff],
            },
        ]
    },
    {
        sectionTitle: "Services",
        pages: [
            {
                _name: 'smgs_printing',
                heading: "SMGS",
                route: "/smgs",
                icon: "bx bx-file-find",
                permission: [Staff],
                children: [
                    {
                        _name: 'smgs_printing_list',
                        heading: "SMGS printing",
                        route: "/list/",
                    },
                    {
                        _name: 'smgs_documents_create',
                        heading: "SMSGS Documents",
                        route: "/documents/",
                    },
                    {
                        _name: 'smgs_single_smgs_list',
                        heading: "Single SMGS",
                        route: "/single/",
                    },
                ]
            },
        ]
    }
]

export default Menu