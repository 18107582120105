<template>

  <b-modal v-model="showModal" title="Create Station" centered hide-footer>
    <section class="p-4 pt-3 row gy-3">
      <div class="col-12 col-md-6">
        <label class="form-label">Station name</label>
        <input v-model="name" type="text" class="form-control" placeholder="Station name">
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label">Station code</label>
        <input v-model="code" type="number" class="form-control" placeholder="Station code">
      </div>
      <div v-if="!isCodeValid" class="col-12">
        <small class="text-center text-danger">
          Code must contain only numbers!
        </small>
      </div>
      <div class="col-12 mt-3 pt-2">
        <b-button v-if="!isCreating" :disabled="!isFormValid"
                  variant="primary" class="w-100" @click="createStation()">Create
        </b-button>

        <button v-if="isCreating" type="button" class="btn btn-primary btn-load w-100">
                <span class="d-flex align-items-center justify-content-center">
                  <span class="me-2">
                    Creating...
                  </span>
                  <span class="spinner-grow" role="status">
                    <span class="visually-hidden">Creating...</span>
                  </span>
                </span>
        </button>

      </div>
    </section>
  </b-modal>

</template>

<script>
import CoreApi from "@/api/core/core";
import {defineComponent} from "vue";
import Swal from "sweetalert2";
import {isNumber} from "lodash/lang";

export default defineComponent({
  name: "CreateStation",
  data() {
    return {
      name: '',
      code: 0,
      isCreating: false,
      showModal: false
    }
  },
  computed: {
    isFormValid() {
      return (this.name.trim().length > 0 && this.code.toString().trim().length > 0) && this.isCodeValid
    },
    isCodeValid() {
      return isNumber(this.code)
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    async createStation() {

      let alert = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      if (this.isFormValid) {
        this.isCreating = true;
        let api = new CoreApi();
        let response = await api.createStation({
          name: this.name,
          code: this.code,
        });
        this.isCreating = false;
        if (response.ok) {
          await alert.fire({
            icon: 'success',
            title: 'Station created successfully'
          })
          this.showModal = false
          this.name = '';
          this.code = '';
        } else {
          await alert.fire({
            icon: 'error',
            title: 'Something went wrong'
          })
        }
      } else {
        await alert.fire({
          icon: 'warning',
          title: 'Please fill all fields'
        })
      }
    },
  },
})

</script>