import {mapActions, mapState} from 'vuex'


export const layoutComputed = {
    ...mapState('layout', {
        layoutType: (state) => state.layoutType,
        sidebarSize: (state) => state.sidebarSize,
        layoutWidth: (state) => state.layoutWidth,
        topbar: (state) => state.topbar,
        mode: (state) => state.mode,
        position: (state) => state.position,
        sidebarView: (state) => state.sidebarView,
        sidebarColor: (state) => state.sidebarColor
    })
}

export const layoutMethods = mapActions('layout',
    ['changeLayoutType', 'changeLayoutWidth', 'changeSidebarSize', 'changeTopbar', 'changeMode', 'changePosition', 'changeSidebarView',
        'changeSidebarColor'])


export const tasksMethods = mapActions('tasks', [
    'addTask', 'initTasks', 'clearAll'
])

export const tasksComputed = {
    ...mapState('tasks', {
        tasks: (state) => {
            if (state.tasks.length > 0) {
                return state.tasks
            } else {
                try {
                    return JSON.parse(localStorage.getItem('tasks') || [])
                } catch {
                    return []
                }
            }
        }
    })
}


export const ordersMehtods = mapActions('orders',
    [
        'setCurrentlyUpdating', 'updateCurrentUpdating', 'fetchAllOrders', 'orders_list',

        'setLotNumber', 'setSendingType', 'setPosition', 'setShipper', 'setConsignee', 'setOrderType',
        'setShipmentStatus', 'setPaymentStatus', 'setDate', 'setDepartureId', 'setDestinationId',
        'setBorderCrossing', 'setConditionsOfCarriage', 'setRollingStock', 'setDeparture',
        'setDestination', 'setCompanyId', 'setProductId', 'setComment', 'addCounterparty', 'removeCounterparty',
        'addContainerType', 'removeContainerType', 'setContainerTypes', 'fullClean', 'setAutoComplete',
        'setRequestStatus', 'setIsPreOrder', 'setPreOrder'
    ]
)
export const orderComputed = {
    ...mapState('orders', {
        orders: (state) => state.orders,

        lot_number: (state) => state.lot_number,
        sending_type: (state) => state.sending_type,
        position: (state) => state.position,
        shipper: (state) => state.shipper,
        consignee: (state) => state.consignee,
        order_type: (state) => state.order_type,
        shipment_status: (state) => state.shipment_status,
        payment_status: (state) => state.payment_status,
        date: (state) => state.date,
        departure_id: (state) => state.departure_id,
        destination_id: (state) => state.destination_id,
        border_crossing: (state) => state.border_crossing,
        conditions_of_carriage: (state) => state.conditions_of_carriage,
        rolling_stock: (state) => state.rolling_stock,
        departure: (state) => state.departure,
        destination: (state) => state.destination,
        company_id: (state) => state.company_id,
        product_id: (state) => state.product_id,
        comment: (state) => state.comment,
        pre_order: (state) => state.pre_order,
        counterparties: (state) => state.counterparties,
        container_types: (state) => state.container_types,

        autocomplete: (state) => state.autocomplete,
        request_status: (state) => state.request_status,
        is_pre_order: (state) => state.is_pre_order,
    })
}


export const wagonOrdersMethods = mapActions('wagonOrder',
    [
        'setLotNumber', 'setPosition', 'setShipper', 'setConsignee', 'setOrderType',
        'setShipmentStatus', 'setPaymentStatus', 'setDate', 'setDepartureId', 'setDestinationId',
        'setBorderCrossing', 'setConditionsOfCarriage', 'setRollingStock', 'setDeparture',
        'setDestination', 'setCompanyId', 'setProductId', 'setComment', 'addCounterparty', 'removeCounterparty',
        'fullClean', 'setAutoComplete', 'setRequestStatus', 'setIsPreOrder',

        'setWeight', 'setQuantity', 'setAgreedRatePerWagon', 'setAgreedRatePerTonne', 'setPreOrder'
    ]
)

export const wagonOrderComputed = {
    ...mapState('wagonOrder', {
        lot_number: (state) => state.lot_number,
        position: (state) => state.position,
        shipper: (state) => state.shipper,
        consignee: (state) => state.consignee,
        order_type: (state) => state.order_type,
        shipment_status: (state) => state.shipment_status,
        payment_status: (state) => state.payment_status,
        date: (state) => state.date,
        departure_id: (state) => state.departure_id,
        destination_id: (state) => state.destination_id,
        border_crossing: (state) => state.border_crossing,
        conditions_of_carriage: (state) => state.conditions_of_carriage,
        rolling_stock: (state) => state.rolling_stock,
        departure: (state) => state.departure,
        destination: (state) => state.destination,
        company_id: (state) => state.company_id,
        product_id: (state) => state.product_id,
        comment: (state) => state.comment,
        weight: (state) => state.weight,
        quantity: (state) => state.quantity,
        agreed_rate_per_wagon: (state) => state.agreed_rate_per_wagon,
        agreed_rate_per_tonne: (state) => state.agreed_rate_per_tonne,

        counterparties: (state) => state.counterparties,
        container_types: (state) => state.container_types,

        autocomplete: (state) => state.autocomplete,
        request_status: (state) => state.request_status,
        is_pre_order: (state) => state.is_pre_order,
        pre_order: (state) => state.pre_order,
    })
}


export const emptyWagonOrderComputed = {
    ...mapState('emptyWagonOrder', {
        lot_number: (state) => state.lot_number,
        position: (state) => state.position,
        shipper: (state) => state.shipper,
        consignee: (state) => state.consignee,
        order_type: (state) => state.order_type,
        shipment_status: (state) => state.shipment_status,
        payment_status: (state) => state.payment_status,
        date: (state) => state.date,
        departure_id: (state) => state.departure_id,
        destination_id: (state) => state.destination_id,
        border_crossing: (state) => state.border_crossing,
        conditions_of_carriage: (state) => state.conditions_of_carriage,
        rolling_stock: (state) => state.rolling_stock,
        departure: (state) => state.departure,
        destination: (state) => state.destination,
        company_id: (state) => state.company_id,
        comment: (state) => state.comment,

        quantity: (state) => state.quantity,
        additional_cost: (state) => state.additional_cost,
        agreed_rate: (state) => state.agreed_rate,

        counterparties: (state) => state.counterparties,

        autocomplete: (state) => state.autocomplete,
        request_status: (state) => state.request_status,
        is_pre_order: (state) => state.is_pre_order,
        pre_order: (state) => state.pre_order,
    })
}


export const emptyWagonOrdersMethods = mapActions('emptyWagonOrder',
    [
        'setLotNumber', 'setPosition', 'setShipper', 'setConsignee', 'setOrderType',
        'setShipmentStatus', 'setPaymentStatus', 'setDate', 'setDepartureId', 'setDestinationId',
        'setBorderCrossing', 'setConditionsOfCarriage', 'setRollingStock', 'setDeparture',
        'setDestination', 'setCompanyId', 'setComment', 'addCounterparty', 'removeCounterparty',
        'fullClean', 'setAutoComplete', 'setRequestStatus', 'setIsPreOrder',

        'setQuantity', 'setAdditionalCost', "setAgreedRate", "setPreOrder"
    ]
)