<template>
  <div class="app-search d-none d-lg-block">

    <div class="position-relative">

      <div class="input-group">
        <select @change="search_from_change()" class="form-select border-0 border-bottom" v-model="search_from"
                style="max-width: 120px">
          <option value="container">Container</option>
          <option value="wagon">Wagon</option>
        </select>
        <input v-model="search" type="text" class="form-control ps-3"
               :placeholder="`Search for ${search_from}s ...`"
               autocomplete="off" id="search-options"/>
      </div>

    </div>

    <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">

      <SimpleBar data-simplebar style="max-height: 320px">

        <div class="dropdown-header mb-0">
          <h6 class="text-overflow text-muted mb-0 text-uppercase">
            {{ search_from === 'container' ? 'Containers' : 'Wagons' }}
          </h6>
        </div>

        <b-list-group class="mt-0" v-if="!isLoading">
          <router-link v-for="result in searchResults" :key="result"
                       :to="getOrderUrl(result.child_type) + result.order_number + `?${[this.search_from]}=${this.search}`">
            <b-list-group-item class="list-group-item-action border-0 border-bottom">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 avatar-xs">
                      <div class="avatar-title bg-soft-info text-info rounded">
                        <i class="ri-netflix-fill"></i>
                      </div>
                    </div>
                    <div class="flex-shrink-0 ms-2">
                      <h6 class="fs-14 mb-0">Order: {{ result.order_number }}</h6>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0">
                    <span class="text-info">
                      <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" class="fs-14"/>
                    </span>
                </div>
              </div>
            </b-list-group-item>
          </router-link>
        </b-list-group>

        <div class="text-center py-4 pb-3" v-if="isLoading && search.trim().length > 2">
          <b-spinner variant="info"></b-spinner>
          <h6 class="mt-2">Searching..</h6>
        </div>

        <div class="text-center py-4 pb-3" v-if="!isLoading && searchResults.length === 0">
          <lottie
              colors="primary:#405189,secondary:#08a88a"
              :options="defaultOptions"
              :height="50"
              :width="50"
          />
          <h6 class="mt-2">No results found</h6>
        </div>
      </SimpleBar>

    </div>
  </div>
</template>

<script>
import {
  SimpleBar
} from "simplebar-vue3";
import spxnqpau from './widgets/msoeawqm.json';
import Lottie from "./widgets/lottie.vue";

import axios from "axios";

export default {
  name: "ContainerSearch",
  data() {
    return {
      search: "",
      isLoading: false,
      searchResults: [],
      defaultOptions: {animationData: spxnqpau},

      search_from: 'container'
    }
  },
  components: {SimpleBar, lottie: Lottie},
  methods: {
    isCustomDropdown() {

      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      searchInput.addEventListener("input", () => {
        if (searchInput.value.trim().length > 2) {
          dropdown.classList.add("show");
        } else {
          dropdown.classList.remove("show");
        }
      });
      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
        }
      });
    },

    async searchContainer(query) {
      let response = await axios.get(`/order/search/`, {
        params: {
          [this.search_from]: query
        }
      });
      return response.data
    },

    getOrderUrl(order_type) {
      return order_type === 'container_order' ? '/orders/container/view/'
          : order_type === 'wagon_order' ? '/orders/wagon/view/'
              : order_type === 'wagon_empty_order' ? '/orders/empty-wagon/view/' : ''
    },

    search_from_change() {
      this.search = ''
      this.searchResults = []
    }
  },
  mounted() {
    this.isCustomDropdown();
  },
  watch: {
    search: function (val) {
      if (val.length > 2) {
        this.searchResults = []
        this.isLoading = true;
        this.searchContainer(val).then((data) => {
          this.isLoading = false;
          this.searchResults = data;
        });
      }
    },
  }
}
</script>

<style scoped>

</style>