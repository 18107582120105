<script>
export default {
  name: "Createavr",
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Select AVR Type" centered hide-footer>
    <div class="text-center mt-3 mb-4">
      <img src="@/assets/images/excel.png" alt="Excel AVR Icon" style="width: 80px; height: 80px">
    </div>
    <div class="d-flex flex-column gap-2">
      <div class="border p-3" style="border-radius: 15px">
        <router-link @click="showModal = false" :to="{name: 'avr_create_container'}">
          <div class="d-flex align-items-center gap-3 flex-wrap">
            <img src="@/assets/images/containers.png" alt="Container Icon" style="width: 40px; height: 40px">
            <h5 class="mb-0">Container Shipments</h5>
          </div>
        </router-link>
      </div>
      <div class="border p-3" style="border-radius: 15px">
        <router-link @click="showModal = false" :to="{name: 'avr_create_wagon'}">
          <div class="d-flex align-items-center gap-3 flex-wrap">
            <img src="@/assets/images/cargo-wagon.png" alt="Loaded Wagon Icon" style="width: 40px; height: 40px">
            <h5 class="mb-0">Conventional Wagons</h5>
          </div>
        </router-link>
      </div>
      <div class="border p-3" style="border-radius: 15px">
        <router-link @click="showModal = false" :to="{name: 'avr_create_empty_wagon'}">
          <div class="d-flex align-items-center gap-3 flex-wrap">
            <img src="@/assets/images/empty-wagon.png" alt="Empty Wagon Icon" style="width: 40px; height: 40px">
            <h5 class="mb-0">Empty Wagons</h5>
          </div>
        </router-link>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>

</style>